<template>
  <div class="log">
    <a-spin :spinning = "spinning">
    <div class="search">
      <div>
        操作日期&nbsp;
        <a-range-picker @change="dateChange" class="rangePicker" v-model="searchValue"/>
        <a-button type="primary" style="margin-left: 20px" @click="searchBtn">查询</a-button>
      </div>
      <a-button @click="exportBtn">
        <a-icon style="color: #00A854;" type="file-excel"/>
        批量导出
      </a-button>

    </div>
    <div class="table">
      <a-alert :message="$router.currentRoute.name==='ARTICLE_SINGLE'?'通过APP点播专题目录点击文章，没有记录':'通过APP点播专题目录点击视频，没有记录'"
               type="info" show-icon style="margin-bottom: 15px" />
      <a-table
          :rowKey="record=>record.id"
          :pagination="false"
          :columns="columns"
          :scroll="{ x: 1500}"
          :data-source="table">
        <span slot="content" slot-scope="text, record" :title="record.title"   style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;">
          {{record.title}}
        </span>
        <span slot="location" slot-scope="text, record">
          {{returnTitle(record.location)}}
        </span>
        <span slot="contentType" slot-scope="text, record">
            {{findValueByKey(content_type,record.contentType)}}
        </span>
        <span slot="channel" slot-scope="text, record">
            {{record.channel!==null?findValueByKey(channel,record.channel):'未知'}}
        </span>

        <span slot="topicTitle" slot-scope="text, record" :title="record.topicTitle"  style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;">
          <span v-if="record.topicTitle!==null"   >
            {{record.topicTitle}}
          </span>
          <span v-else>
            ——
          </span>
        </span>

      </a-table>
      <!--分页-->
      <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      :page-size-options="pageSizeOptions"
                      :page-size.sync="pagination.pageSize"
                      show-size-changer show-quick-jumper
                      @showSizeChange="pageChange"
                      @change="pageChange"/>
      </div>
    </div>
    </a-spin>
  </div>
</template>

<script>
import {getUserActionLog} from "@/service/operationLog";
import headerConfig from "@/service/api_header_config";
import codeList from "@/assets/configurationFiles/EduDrugCodeList.json";
export default {
  name: "videoLog",
  data() {
    return {
      spinning:false,
      location:this.$store.state.location,
      content_type:this.$store.state.content_type,
      channel:this.$store.state.channel,
      searchValue: [],
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      table: [],
      columns: [
        {
          title: '姓名',
          align:'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align:'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align:'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align:'center',
        },
        {
          title: this.$router.currentRoute.name==='VOD_SINGLE'?'点击内容标题':'文章标题',
          scopedSlots: {customRender: "content"},
          align:'center',
        },
        {
          title: '内容类型',
          scopedSlots: {customRender: "contentType"},
          align:'center',
        },
        {
          title: '点击位置',
          scopedSlots: {customRender: "location"},
          align:'center',
        },
        {
          title: '专题名称',
          align:'center',
          scopedSlots: {customRender: "topicTitle"},
        },
        {
          title: '操作渠道',
          align:'center',
          scopedSlots: {customRender: "channel"},
        },
        {
          title: '操作时间',
          align:'center',
          dataIndex: 'createdTime',
        },
      ],
      pageSizeOptions: ['10', '30', '50'],
    }
  },
  watch: {
    $route(to) { //监控路由地址变更
      this.pagination.current=1
      this.searchValue=[]
      if (this.$router.currentRoute.name==='ARTICLE_SINGLE'){
        this.columns = this.columns.filter(obj => obj.title !== '内容类型');
      }else {
        this.columns=[
          {
            title: '姓名',
            align:'center',
            dataIndex: 'name',
          },
          {
            title: '医院',
            align:'center',
            dataIndex: 'hospitalName',
          }, {
            title: '科室',
            dataIndex: 'hospitalOffices',
            align:'center',
          },
          {
            title: '职称',
            dataIndex: 'jobTitle',
            align:'center',
          },
          {
            title: this.$router.currentRoute.name==='VOD_SINGLE'?'点击内容标题':'文章标题',
            scopedSlots: {customRender: "content"},
            align:'center',
          },
          {
            title: '内容类型',
            scopedSlots: {customRender: "contentType"},
            align:'center',
          },
          {
            title: '点击位置',
            scopedSlots: {customRender: "location"},
            align:'center',
          },
          {
            title: '专题名称',
            align:'center',
            scopedSlots: {customRender: "topicTitle"},
          },
          {
            title: '操作渠道',
            align:'center',
            scopedSlots: {customRender: "channel"},
          },
          {
            title: '操作时间',
            align:'center',
            dataIndex: 'createdTime',
          },
        ]
      }
      this.getData()
    },
  },
  mounted() {
    if (this.$router.currentRoute.name==='ARTICLE_SINGLE'){
      this.columns = this.columns.filter(obj => obj.title !== '内容类型');
    }else {
      this.columns=[
        {
          title: '姓名',
          align:'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align:'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align:'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align:'center',
        },
        {
          title: this.$router.currentRoute.name==='VOD_SINGLE'?'点击内容标题':'文章标题',
          scopedSlots: {customRender: "content"},
          align:'center',
        },
        {
          title: '内容类型',
          scopedSlots: {customRender: "contentType"},
          align:'center',
        },
        {
          title: '点击位置',
          scopedSlots: {customRender: "location"},
          align:'center',
        },
        {
          title: '专题名称',
          align:'center',
          scopedSlots: {customRender: "topicTitle"},
        },
        {
          title: '操作渠道',
          align:'center',
          scopedSlots: {customRender: "channel"},
        },
        {
          title: '操作时间',
          align:'center',
          dataIndex: 'createdTime',
        },
      ]
    }

    this.getData()
  },
  methods: {
    returnTitle(code) {
      let list = codeList;
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].code) {
          return list[i].title;
        }
      }
    },
    findValueByKey(arr, key) {
      // 检查对象中是否存在给定的键
      if (arr.hasOwnProperty(key)) {
        // 如果存在，返回相应的属性值
        return arr[key];
      } else {
        // 如果不存在，返回 null 或者你认为适合的默认值
        return null;
      }
    },
    async getData(){
      this.spinning=true
      const data ={
        curPage:this.pagination.current,
        pageSize:this.pagination.pageSize,
        startTime:this.searchValue[0],
        endTime:this.searchValue[1],
        type:this.$router.currentRoute.name
      }
      const ref =await getUserActionLog(data)
      if (ref.statuscode===200){
        this.table=ref.data
        this.pagination.total=ref.count
      }
      this.spinning=false
    },

    dateChange(date, dateString) {
      this.pagination.current=1
      this.searchValue = dateString
      console.log(this.searchValue)
      this.getData()
    },
    searchBtn() {
      this.getData()
    },
    exportBtn() {
      let startTime=this.searchValue[0]===undefined?'':this.searchValue[0]
      let endTime = this.searchValue[1]===undefined?'':this.searchValue[1]
      let url =  headerConfig.api_header + '/v3/userAction/content/export?'
      +'startTime='+startTime+'&endTime='+endTime+'&type='+this.$router.currentRoute.name
      window.open(url)
    },
    pageChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData()
    },
  }
}
</script>
<style scoped lang="scss">
.log {
  margin: -15px -15px;
  background-color: #f0f2f5;
  .search {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .table {
    background-color: #fff;
    padding: 20px;
  }
}

.pageView {
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pageLeft {
  font-size: 15px;
  color: #929292
}
</style>